


function scrollAnimation(less, event) {

}

function navDisabledIMPLEMENTATION() {
    childSplitnavA = new SplitText(".items-section .text", { type: "chars", linesClass: "split-child" });
    parentSplitnav = new SplitText(".items-section .text", { type: "lines", linesClass: "split-parent" });

    gsap.set(childSplitnavA.chars, { yPercent: 0, ease: Power4.easeInOut, stagger: 0 });
}

hoverNav = false

function navDisabled(inout, kill, device) {

    var widthDevice = '';
    var widthLogo = '';
    if (device == 'mobile') {
        widthDevice = '67px';
        widthLogo = '90px';

    } else {
        widthDevice = '110px'
        widthLogo = '120px';
    }
    if (device == 'mobile' && inout == 'out') {
        $('.clickMobiles').removeClass('unabled')
    }

    onloadtl1 = gsap.timeline({});

    function navOut() {
        var tl01 = gsap.timeline({});
        tl01.to(childSplitnavA.chars, { duration: .85, yPercent: 100, ease: Power2.easeInOut, reversed: true, stagger: 0.005 }, 0);
        tl01.to(('#nav .content'), 1, { width: widthDevice, ease: Expo.easeInOut }, .05)
        tl01.to(('#nav .logo'), .45, { width: '68px', ease: Power2.easeOut }, .35)
        tl01.to(('#nav .hidelogo'), .45, { opacity: 0, autoAlpha: 0, ease: Power2.easeOut }, .35)
    }
    function navIn() {
        var tl01 = gsap.timeline({});
        tl01.to(childSplitnavA.chars, { duration: .85, yPercent: 0, ease: Power2.easeInOut, stagger: 0.005 }, 0);
        tl01.to(('#nav .content'), 1, { width: '230px', ease: Expo.easeInOut }, 0)
        tl01.to(('#nav .logo'), .45, { width: widthLogo, ease: Power2.easeOut }, .45)
        tl01.to(('#nav .hidelogo'), .45, { opacity: 1, autoAlpha: 1, ease: Power2.easeOut }, .45)
    }


    function hovering() {
        if (device != 'mobile') {
            $('.onvideo').on("mouseenter", function () {
                navIn();
            })
            $('.onvideo').on("mouseleave", function () {
                navOut();
            })
            hoverNav = true;
        }
    }


    if (inout == 'out') {
        navOut();
        hovering();
    }
    if (inout == 'in') {
        navIn();
    }
    if (kill == 'kill' && hoverNav == true) {
        hovering();
        $('.onvideo').off('mouseenter mouseleave');
        hoverNav = false;
    }
}

if (isMobile()) {
    setTimeout(() => {
        navDisabled('out', '', 'mobile');
        $('.clickMobiles').on('click', function () {
            navDisabled('in', '', 'mobile');
            $('.clickMobiles').addClass('unabled')
        });

        window.addEventListener('click', function (e) {
            if (document.getElementById('nav').contains(e.target)) {
            } else {
                navDisabled('out', '', 'mobile');
                $('.clickMobiles').removeClass('unabled')
            }
        });
    }, 500)

}

function textsOnInitIMPLEMENTATION() {
    childSplitA = new SplitText(".maintitle", { type: "words,chars", charsClass: "thechars" });
    childSplitB = new SplitText(".mainp", { type: "words", linesClass: "split-child" });
    childSplitC = new SplitText(".linksplitscrolla", { type: "lines", linesClass: "split-child" });
    childSplitD01 = new SplitText(".overVideoReel-txt1", { type: "chars", charsClass: "thechars1" });
    childSplitD02 = new SplitText(".overVideoReel-txt2", { type: "chars", charsClass: "thechars2" });
    parentSplit = new SplitText(".maintitle, .mainp, .linksplitscrolla, .overVideoReel-txt1, .overVideoReel-txt2", { type: "lines", linesClass: "split-parent" });

    gsap.set(childSplitA.chars, { duration: 0, yPercent: 100 });
    gsap.set(childSplitB.words, { duration: 0, yPercent: 100 });
    gsap.set(childSplitC.lines, { duration: 0, yPercent: 100 });
    gsap.set(childSplitD01.chars, { duration: 0, yPercent: 0 });
    gsap.set(childSplitD02.chars, { duration: 0, yPercent: 100 });
}

var initialized = false;
function textsOnInit(inout, origin) {

    if (inout == 'init') {
        var tl01 = gsap.timeline({});
        tl01.to(childSplitA.chars, { duration: .75, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 }, .5);
        tl01.to(childSplitB.words, { duration: .75, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 }, .5);
        tl01.to(childSplitC.lines, { duration: .75, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 }, .6);
        tl01.to('.group-btns01 .buton01 .linksplitscrolla', { duration: .75, yPercent: 0, ease: Power2.easeInOut, opacity: 1, }, .6);
        tl01.to('.imageplaceholder', 1, { opacity: 1 }, 0);
        initialized = true;
    }
    else if (inout == 'in' && initialized == true) {
        var tl01 = gsap.timeline({});
        tl01.to(childSplitA.chars, { duration: .75, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 }, .5);
        tl01.to(childSplitB.words, { duration: .75, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 }, .5);
        tl01.to(childSplitC.lines, { duration: .75, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 }, .6);
        tl01.to('.group-btns01 .buton01 .linksplitscrolla', { duration: .75, yPercent: 0, ease: Power2.easeInOut, opacity: 1, }, .6);
        tl01.to('.imageplaceholder', 1, { opacity: 1 }, 0);

    } else if (inout == 'out') {
        var tl02 = gsap.timeline({});
        tl02.to(childSplitA.chars, { duration: .75, yPercent: 150, ease: Power2.easeInOut, reversed: true, stagger: 0.02 }, 0);
        tl02.to(childSplitB.words, { duration: .75, yPercent: 150, ease: Power2.easeInOut, reversed: true, stagger: 0.01 }, 0);
        tl02.to(childSplitC.lines, { duration: .75, yPercent: 150, ease: Power2.easeInOut, reversed: true, stagger: 0.01 }, 0);
        tl02.to('.group-btns01 .buton01 .linksplitscrolla', { duration: .75, yPercent: 50, ease: Power2.easeInOut, reversed: false, opacity: 0 }, 0);
        if (origin == 'click') {
            tl02.to(('.imageplaceholder'), 1.5, { opacity: 0 }, .9);
        }
    }
}

function hoverbtn01() {

    var childSplitScrollA = new SplitText(".splita", { type: "chars", linesClass: "split-child" });
    var parentSplitcA = new SplitText(".splita", { type: "lines", linesClass: "split-parent" });
    var textanimationscrollA = childSplitScrollA.chars;


    var sections = gsap.utils.toArray(textanimationscrollA);

    sections.forEach((section) => {

        var target = $(section).closest('.linksplitscrolla');

        $(section).append('<div class="childscrolla">' + section.textContent + '</div>');

        function mouseEnter() {
            gsap.to(section, 1.6, { yPercent: -100, ease: Elastic.easeOut.config(1.2, .8), stagger: 2, });
        }
        function mouseLeave() {
            gsap.to(section, 1.6, { yPercent: 0, ease: Elastic.easeOut.config(1.2, .8), stagger: 2 });
        }

        $(target).on("mouseenter", mouseEnter);
        $(target).on("mouseleave", mouseLeave);

    })
}

function cursorCircle() {

    if (isMobile() == false) {
        gsap.set(".circleP", { xPercent: -50, yPercent: -50 });

        var ball = document.querySelector(".circleP");
        var pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        var mouse = { x: pos.x, y: pos.y };
        var speed = 0.08;

        var fpms = 60 / 1000;

        var xSet = gsap.quickSetter(ball, "x", "px");
        var ySet = gsap.quickSetter(ball, "y", "px");

        window.addEventListener("mousemove", e => {
            mouse.x = e.x;
            mouse.y = e.y;
        });

        gsap.ticker.add((time, deltaTime) => {

            var delta = deltaTime * fpms;
            var dt = 1.0 - Math.pow(1.0 - speed, delta);

            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
        });
    }
}

function vimmeoIMPLEMENTATION() {
    setTimeout(() => {
        iframe = document.querySelector('#iframe');
        player = new Vimeo.Player(iframe);
        gsap.set($('.countdown__progress, .countdown__background'), { drawSVG: "0%", opacity: 0, visibility: "visible" })
        gsap.set($('.countdown__load'), { drawSVG: "0%, 10%", opacity: 0, visibility: "visible" })

        childSplitCursorA = new SplitText(".textP .text01", { type: "chars", charsClass: "thechars" });
        childSplitCursorB = new SplitText(".textP .text02", { type: "chars", linesClass: "split-child" });
        parentSplit = new SplitText(".textP .txt", { type: "lines", linesClass: "split-parent" });
        gsap.set(childSplitCursorA.chars, { duration: 0, yPercent: 150 });
        gsap.set(childSplitCursorB.chars, { duration: 0, yPercent: 150 });
        //  gsap.set(parentSplit, {duration: 0, yPercent: 0});        

        cursorCircle();
    }, 500)
}

function circleLoadingINIT() {
    var tl01 = gsap.timeline({});
    tl01.to(('.countdown__load'), 1.3, { opacity: 0 }, 1);
    tl01.to(childSplitCursorA.chars, { duration: .75, yPercent: -150, ease: Power2.easeInOut, stagger: 0.02 }, 0);
    tl01.to(childSplitCursorB.chars, { duration: .75, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 }, 1);
}
function circleProgressSET() {
    gsap.to(".countdown__progress", .4, { opacity: 1, ease: Linear.easeNone })
}
function circleProgress(percent) {
    gsap.to(".countdown__progress", 1.45, { drawSVG: ' ' + percent + '', ease: Linear.easeNone })
}
function circleLoading() {
    var tl02 = gsap.timeline({ repeat: -1 });
    tl02.to("#circleProgress", 0, { opacity: 1 });
    tl02.to(".countdown__load", .9, { drawSVG: '100%, 110%', ease: Linear.easeNone });

    var tl01 = gsap.timeline({});
    tl01.to(childSplitCursorA.chars, { duration: .75, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 }, 0);
    tl01.to(".countdown__background", .5, { opacity: 1, drawSVG: '100%', ease: Linear.easeNone }, 0);
    tl01.set(".countdown__load", { opacity: 1 }, 0);
}
function circleLoadingOUT() {
    var tl01 = gsap.timeline({});
    tl01.to(childSplitCursorB.chars, { duration: .75, yPercent: -150, ease: Power2.easeInOut, stagger: 0.02 }, .15);
    tl01.to(".countdown__background, .countdown__load", .5, { opacity: 0, }, .7);
    tl01.to(".countdown__progress", .5, { opacity: 0, }, .7);
    tl01.set(childSplitCursorA.chars, { duration: 0, yPercent: 150 }, 2);
    tl01.set(childSplitCursorB.chars, { duration: 0, yPercent: 150 }, 2);

}




var vdo_play = "";
function videotime(param) {
    circleProgressSET();
    var currentPos, percentage, vdoEndTym = "";
    var time = 1400;
    if (param == 'kill') {
        time = 1000000;
    }
    vdo_play = setInterval(function () {
        player.on('timeupdate', function (getAll) {
            currentPos = getAll.seconds; //get currentime
            vdoEndTym = getAll.duration; //get video duration
            percentage = (getAll.percent * 100) + "%";
            circleProgress(percentage);
        });
    }, time);
}

function reel(urlVid, origin) {
    let opts = {
        url: urlVid,
        controls: 0,
        playsinline: true,
    }
    if (origin !== 'home') {
        player.destroy();
        player = new Vimeo.Player('main-iframe-container', opts);
        vimmeo('onplay');
    } else {
        vimmeo(origin);
    }
    textsOnInit('out', 'click');
}

function fadeVolumeOut() {
    player.getVolume().then(function (volume) {
        player.getMuted().then(function (muted) {
            if (volume >= 1 && muted != true) {
                var i = 1 * 10;
                var ref = setInterval(() => {
                    --i / 10
                    player.setVolume(i / 10)
                    if (i / 10 == 0) clearInterval(ref);
                }, 80);
            }
        })
    })
}
function fadeVolumeIn() {
    var i = 1;
    var ref = setInterval(() => {
        ++i / 10
        player.setVolume(i / 10)
        if (i / 10 == 1) clearInterval(ref);
    }, 80);
}


function vimmeo(moment) {
    if (moment == 'onplay') {
        $('#nav').addClass('onvideo');
        navDisabled('out');
        circleLoading();
        modalJS('close');
        player.play();
        // soundBackground('out');



        player.on('play', function () {
            circleLoadingINIT();
            $('#videoOverlay').addClass('play');
            gsap.to(('#mainoverlay'), .5, { opacity: 0, })

            if (vdo_play) {
                clearInterval(vdo_play);
            }
            videotime();
        });

        player.on('ended', function () {
            endVideo();
        });

        $('#videoOverlay').on('click', () => {
            endVideo();
        })

    } else if (moment == 'home') {

        // soundBackground('out');
        player.play();
        fadeVolumeIn();

        $('#nav').addClass('onvideo');
        navDisabled('out');
        circleLoading();
        circleLoadingINIT();
        $('#videoOverlay').addClass('play');
        videotime();


        $('#videoOverlay').on('click', () => {
            endVideo('home');
            // click1()
            fadeVolumeOut();

        })

    }
    if (isMobile()) {
        setTimeout(() => {
            hideNavMobile('hide');
        }, 500)
    }
    gsap.to('.sound', { duration: .9, opacity: 0, ease: Power2.easeNone },);
}


function hideNavMobile(moment) {
    movement = '';
    if (moment == 'hide') {
        movement = '-70px';
    } else {
        movement = '0px';
    }
    gsap.to('#nav', { duration: .7, left: movement, ease: Power4.easeOut },);
}


function endVideo(moment) {
    if (!isMobile()) {
        navDisabled('in', 'kill');
    } else {
        hideNavMobile('show');
    }
    setTimeout(() => {
        textsOnInit('in');
        circleLoadingOUT();
        // soundBackground('in');
    }, 500);
    $('#videoOverlay').removeClass('play');
    gsap.to('.sound', { duration: .9, opacity: 1, ease: Power2.easeNone },);
    if (moment == 'home') {

    } else {
        player.pause();
        setTimeout(() => {
            player.unload();
        }, 2000)
    }
}

loopStatus = true;
repeatStatus = -1;

height = '2px';
const tl01 = gsap.timeline({ repeat: repeatStatus, yoyo: loopStatus });
const tl02 = gsap.timeline({ repeat: repeatStatus, yoyo: loopStatus, delay: .3 });
const tl03 = gsap.timeline({ repeat: repeatStatus, yoyo: loopStatus, delay: .6 });

setTimeout(() => {
    tl01.to('.bar01', { duration: 1.1, height: height, ease: Power2.easeNone },);
    tl02.to('.bar02', { duration: 1, height: height, ease: Power2.easeNone },);
    tl03.to('.bar03', { duration: .9, height: height, ease: Power2.easeNone },);
}, 1000)



function soundbars(param) {
    if (param == 'on') {
        var tl01Off = gsap.timeline({});
        tl01Off.to('.bar', { duration: .6, opacity: 1, ease: Power2.easeNone },);

        tl01.resume()
        tl02.resume()
        tl03.resume()
    } else if (param == 'off') {

        tl01.pause();
        tl02.pause();
        tl03.pause();

        var tl01Off = gsap.timeline({});
        tl01Off.to('.bar', { duration: .6, opacity: .35, ease: Power2.easeNone },);
    }

}


function sound(param) {
    soundbars(param);
}

setTimeout(() => {
    let vall = "on";
    let vallText = "Disable audio";

    $('.sound').on('click', function () {
        vall = vall === "on" ? "off" : "on";
        vallText = vallText === "Disable audio" ? "Enable audio" : "Disable audio";

        if (vallText == 'Enable audio') {
            Howler.volume(0, 1500);
        } else if (vallText == 'Disable audio') {
            Howler.volume(1, 1500);
        }
        soundbars(vall);
        $('.sound .tooltip > span').html(vallText)
    })
}, 1000)


function modalJS(moment) {
    setTimeout(() => {

        movement = '';
        if (moment == 'open') {
            movement = 0;
            $('#modal').addClass('open');

        } else if (moment == 'close') {
            if (isMobile()) {
                movement = '-80vw';
            } else {
                movement = '-550px';
            }
            $('#modal').removeClass('open')
        }

        var tl01 = gsap.timeline({});
        tl01.to('.modal01', .8, { right: movement, ease: Power4.easeOut },);

    }, 50)
    if (moment == 'open' && isMobile()) {
        hideNavMobile('hide');
    } else if (moment == 'close' && isMobile()) {
        hideNavMobile('show');

    }

}

function modalJSHovers() {
    const videosModals = Array.from(document.getElementsByClassName('overVideoReel'));
    const videosModals02 = Array.from(document.getElementsByClassName('overVideoReel02'));
    const videosModals03 = Array.from(document.getElementsByClassName('overVideoReel03'));
    videosModals.forEach(videosModal => {
        var otherInput = $(videosModal).find('.thechars1');
        var otherInput2 = $(videosModal).find('.thechars2');
        var otherInput3 = $(videosModal).find('.circleArrow');


        function mouseEnter() {
            gsap.to(otherInput, { duration: .4, yPercent: -100, ease: Power2.easeInOut, stagger: 0.02 });
            gsap.to(otherInput2, { duration: .4, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 });
            gsap.to(otherInput3, { duration: .4, scale: .85, ease: Power2.easeInOut });
        }
        function mouseLeave() {
            gsap.to(otherInput, { duration: .4, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 });
            gsap.to(otherInput2, { duration: .4, yPercent: 100, ease: Power2.easeInOut, stagger: 0.02 });
            gsap.to(otherInput3, { duration: .4, scale: 1, ease: Power2.easeInOut });
        }

        $(videosModal).on("mouseenter", mouseEnter);
        $(videosModal).on("mouseleave", mouseLeave);
    });
    videosModals02.forEach(videosModal => {
        var otherInput = $(videosModal).find('.thechars1');
        var otherInput2 = $(videosModal).find('.thechars2');
        var otherInput3 = $(videosModal).find('.circleArrow');


        function mouseEnter() {
            gsap.to(otherInput, { duration: .5, yPercent: -100, ease: Power2.easeInOut, stagger: 0.002 });
            gsap.to(otherInput2, { duration: .5, yPercent: 0, ease: Power2.easeInOut, stagger: 0.002 });
            gsap.to(otherInput3, { duration: .5, scale: .85, ease: Power2.easeInOut });
        }
        function mouseLeave() {
            gsap.to(otherInput, { duration: .5, yPercent: 0, ease: Power2.easeInOut, stagger: 0.002 });
            gsap.to(otherInput2, { duration: .5, yPercent: 100, ease: Power2.easeInOut, stagger: 0.002 });
            gsap.to(otherInput3, { duration: .5, scale: 1, ease: Power2.easeInOut });
        }

        $(videosModal).on("mouseenter", mouseEnter);
        $(videosModal).on("mouseleave", mouseLeave);
    });
    videosModals03.forEach(videosModal => {
        var otherInput = $(videosModal).find('.thechars1');
        var otherInput2 = $(videosModal).find('.thechars2');
        var otherInput3 = $(videosModal).find('.circleArrow');


        function mouseEnter() {
            gsap.to(otherInput, { duration: .5, yPercent: -100, ease: Power2.easeInOut, stagger: 0.008 });
            gsap.to(otherInput2, { duration: .5, yPercent: 0, ease: Power2.easeInOut, stagger: 0.008 });
            gsap.to(otherInput3, { duration: .5, scale: .85, ease: Power2.easeInOut });
        }
        function mouseLeave() {
            gsap.to(otherInput, { duration: .5, yPercent: 0, ease: Power2.easeInOut, stagger: 0.008 });
            gsap.to(otherInput2, { duration: .5, yPercent: 100, ease: Power2.easeInOut, stagger: 0.008 });
            gsap.to(otherInput3, { duration: .5, scale: 1, ease: Power2.easeInOut });
        }

        $(videosModal).on("mouseenter", mouseEnter);
        $(videosModal).on("mouseleave", mouseLeave);
    });

}

function postProductionVideos() {
    setTimeout(() => {
        const options = {
            url: 'https://player.vimeo.com/video/764256490?h=88464ee24e',
            badge: 0,
            playsinline: 1,
            loop: 0,
            controls: 0,
        };
        const options02 = {
            url: 'https://player.vimeo.com/video/764253160?h=88464ee24e',
            badge: 0,
            playsinline: 1,
            loop: 0,
            controls: 0,
        };

        $('.post-p-video-02').on('click', function () {
            player.destroy()
            player = new Vimeo.Player('main-iframe-container', options);
            vimmeo('onplay');
            textsOnInit('out', 'click');
            modalJS('close');
        })
        $('.post-p-video-03').on('click', function () {
            player.destroy()
            player = new Vimeo.Player('main-iframe-container', options02);
            vimmeo('onplay');
            textsOnInit('out', 'click');
            modalJS('close');
        })
    }, 2000)
}



window.addEventListener('click', function (e) {
    if (document.getElementById('modal').contains(e.target)) {
    } else {

        if ($('#modal').hasClass('open')) {
            modalJS('close');
            // click1();
        }
    }
});


function contactPageOnInit(moment) {
    if (moment == 'in') {
        childSplitContA = new SplitText(".appearChars01", { type: "chars", linesClass: "split-child" });
        parentSplitnav = new SplitText(".appearChars01, .appearChars02", { type: "lines", linesClass: "split-parent" });

        gsap.set(childSplitContA.chars, { yPercent: 100, duration: 0, ease: Power4.easeInOut, stagger: 0 });
        gsap.set('.appearOpacity01, .appearOpacity02', { yPercent: 10, duration: 0, opacity: 0 });
        gsap.set('.appearOpacity03', { yPercent: 10, duration: 0, opacity: 0 });
        gsap.set('.appearOpacity04', { yPercent: 20, duration: 0, opacity: 0 });


        var tl01 = gsap.timeline({});
        tl01.to(childSplitContA.chars, { duration: .85, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02 }, .75);
        tl01.to('.appearOpacity01', { duration: .55, yPercent: 0, opacity: 1, }, .9);
        tl01.to('.appearOpacity02', { duration: .55, yPercent: 0, opacity: 1, }, .9);
        tl01.to('.appearOpacity03', { duration: .55, yPercent: 0, opacity: 1, }, 1.2);
        tl01.to('.appearOpacity04', { duration: .55, yPercent: 0, opacity: 1, }, 1.2);
    } else if (moment == 'out') {
        var tl02 = gsap.timeline({});
        tl02.to(childSplitContA.chars, { duration: .85, yPercent: 100, ease: Power2.easeInOut, stagger: 0.02 }, 0);
        tl02.to('.appearOpacity01', { duration: .55, yPercent: 10, opacity: 0, }, 0);
        tl02.to('.appearOpacity02', { duration: .55, yPercent: 10, opacity: 0, }, 0);
        tl02.to('.appearOpacity03', { duration: .55, yPercent: 10, opacity: 0, }, 0);
        tl02.to('.appearOpacity04', { duration: .55, yPercent: 20, opacity: 0, }, 0);

    }
}

function homeVideoLoop() {
    setTimeout(() => {
        player.play()
    }, 1000)
}

function isMobile() {
    if ($(window).width() > 1060) {
        return false
    } else {
        return true
    }
}

function carouselsContact() {
    if (isMobile() == true) {
        setTimeout(() => {

            // Play with this value to change the speed
            let projslider = '';
            let projslider02 = '';
            let isPaused = false;
            const slideshowEl = document.querySelector(".flick");
            const slideshowEl02 = document.querySelector(".flick02");

            projslider = new Flickity(slideshowEl, {
                prevNextButtons: false,
                pageDots: false,
                draggable: true,
                wrapAround: false,
                cellAlign: 'left',
                autoPlay: false,
                pauseAutoPlayOnHover: false,
                selectedAttraction: 0.020,
                friction: 0.2
            });
            projslider02 = new Flickity(slideshowEl02, {
                prevNextButtons: false,
                pageDots: false,
                draggable: true,
                wrapAround: false,
                cellAlign: 'left',
                autoPlay: false,
                pauseAutoPlayOnHover: false,
                selectedAttraction: 0.020,
                friction: 0.2
            });



        }, 300)

    }
}

// var disableAudios = false
// var audiosInit = false;

//     var SoundBackground = new Howl({
//         src: ['assets/sounds/background.mp3'],
//         loop: true,
//         volume: 1,
//       });
//     var SoundHover1 = new Howl({
//         src: ['assets/sounds/hover1.mp3']
//       });
//     var SoundHover2 = new Howl({
//         src: ['assets/sounds/hover2.mp3']
//           });
//     var SoundClick = new Howl({
//         src: ['assets/sounds/click.mp3']
//       });

//   function hover1() {
//     console.log('enter')
//     SoundHover1.play();
// }
//   function hover2() {
//     SoundHover2.play();
//   }
//   function click1() {
//     if(!isMobile() && disableAudios) {
//     SoundClick.play();
//     }
//   }

// function soundBackground(moment) {
//     if(moment == 'in') {
//         SoundBackground.fade(0, 1, 1500);
//     } else if(moment == 'out') {
//         SoundBackground.fade(1, 0, 1500);
//     } else if (moment == 'init') {
//         Howler.volume(1);
//         SoundBackground.volume(0);
//         SoundBackground.play();
//         SoundBackground.fade(0, 1, 1500);
//     }
// }

// function audios() {
//     if(audiosInit == true && !isMobile() && disableAudios) {
//   $('.btn, .butonLead, .overVideoReel').on("mouseenter", hover1);
//   $('.director').on("mouseenter", hover2);
//   $('.sound, .btn, .href, .legal .rs, #videoOverlay').on('click', function() {
//     click1()
//   })
// }
// }

// document.addEventListener("visibilitychange", (event) => {
//     if (document.visibilityState == "visible") {
//         soundBackground('in')
//     } else {
//         soundBackground('out')
//     }
//   });


function init() {
    initText01 = new SplitText(".txtheadphones", { type: "words", linesClass: "split-child" });

    gsap.set(initText01.words, { yPercent: 100, duration: 0, opacity: 0, ease: Power4.easeInOut, stagger: 0 });
    // gsap.set('.init .logoheadphones', {yPercent: -20, duration:0, opacity:0, ease: Power4.easeInOut, stagger: 0});    
    gsap.set('.init .linksplitscrolla', { yPercent: 20, duration: 0, opacity: 0, ease: Power4.easeInOut, stagger: 0 });

    var tl01 = gsap.timeline({});
    tl01.to(('.init .linksplitscrolla'), { duration: 1, yPercent: 0, opacity: 1, }, 2.9)
    tl01.to(('.init .section1'), { duration: 1, opacity: 1, yPercent: 10 }, 1.2)
    // tl01.to(('.init .logoheadphones'),  {yPercent: 0, duration:1, opacity:1, }, 1.2)
    tl01.to(initText01.words, { duration: .85, yPercent: 0, opacity: 1, stagger: 0.15 }, .75);

    setTimeout(() => {
        // gsap.to('.init .split-child', {duration: .75, yPercent: 0, ease: Power2.easeInOut, stagger: 0.02});  
        initClick()
    }, 3000)

}

function initClick() {
    setTimeout(() => {
        var tl01 = gsap.timeline({});
        tl01.to('.init', { duration: .5, opacity: 0, autoAlpha: 0, yPercent: -60, });
        tl01.to(('#initoverlay'), { duration: 1, opacity: 0, }, 0)
        tl01.to(('#nav'), { duration: .5, left: 0, }, 0);
        // tl01.to(('.sound'), {duration:.5, opacity:1, autoAlpha:1, }, 0);
        textsOnInit('init');
        // if(!isMobile() && disableAudios) {
        //     audiosInit = true;
        //     soundBackground('init');
        //     audios();    
        // }
    }, 0)
}